import React from 'react'
import SetPassword from '../components/SetPassword/SetPassword'
import SEO from '../components/SEO'

export default () => (
  <div style={{ backgroundColor: '#f5f5f5' }}>
    <SEO title='Set Password' />
    <SetPassword />
  </div>
)
