import React from 'react'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import { Button, CardFooter, CustomInput } from 'sqrl-design-system'

const FormSchema = Yup.object({
  password: Yup.string().required('Password is required'),
  passwordConfirmation: Yup.string()
    .required()
    .oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
})

const SetPasswordForm = () => (
  <div>
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={FormSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values)
        setSubmitting(false)
      }}
      render={props => (
        <form name="setPassword" onSubmit={props.handleSubmit}>
          <div className="field-wrapper">
            <Field
              className={
                props.touched.password && props.errors.password
                  ? 'is-invalid'
                  : ''
              }
              type="password"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.password}
              id="password"
              name="password"
              label="Password"
              component={CustomInput}
            />

            <Field
              className={
                props.touched.passwordConfirmation &&
                props.errors.passwordConfirmation
                  ? 'is-invalid'
                  : ''
              }
              type="password"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.passwordConfirmation}
              id="passwordConfirmation"
              name="passwordConfirmation"
              label="Confirm Password"
              component={CustomInput}
            />
          </div>

          <CardFooter className="form-footer" style={{ padding: '30px 0' }}>
            <Button className="is-rounded" type="submit">
              Set Password
            </Button>
          </CardFooter>
        </form>
      )}
    />
  </div>
)

export default SetPasswordForm
